<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card style="min-height: 60vh">
          <div class="clearfix" />

          <div>
            <v-card-title v-if="edit">
              Edição prestador de serviço
            </v-card-title>
            <v-card-title v-else> Cadastro prestador de serviço </v-card-title>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="updateProvider"
            >
              <v-card>
                <v-list-item-content>
                  <v-row v-if="!edit" style="justify-content: center">
                    <v-radio-group
                      v-model="select"
                      row
                      @change="
                        ;(form.select = select), $refs.form.resetValidation()
                      "
                    >
                      <v-radio label="Pessoa Física" value="1"></v-radio>
                      <v-radio label="Pessoa Jurídica" value="0"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-list>
                    <v-card-title> Dados do prestador de serviço </v-card-title>

                    <v-list-item-content>
                      <v-row v-if="!loading && select == 1" class="pa-3">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.nome"
                            label="Nome *"
                            name="nome"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.email"
                            name="email"
                            label="Email *"
                            :rules="emailRules"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.telCel"
                            v-mask="maskTelCel"
                            :rules="telRules"
                            label="Telefone Celular *"
                            name="telefone"
                            type="tel"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.cpf"
                            v-mask="maskCpf"
                            name="cpf"
                            label="CPF *"
                            :rules="cpfRules"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.telRes"
                            v-mask="maskTelCel"
                            label="Telefone Comercial"
                            type="tel"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="!loading && select == 0" class="pa-3">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.razaoSocial"
                            label="Razão Social*"
                            name="razaoSocial"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.nomeFantasia"
                            label="Nome Fantasia*"
                            name="nomeFantasia"
                            :rules="requiredRules"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.email"
                            name="email"
                            label="Email *"
                            :rules="emailRules"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="form.cnpj"
                            v-mask="maskCnpj"
                            name="cpf"
                            label="CNPJ *"
                            :rules="cnpjRules"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.telRes"
                            v-mask="maskTelCel"
                            label="Telefone Comercial"
                            type="tel"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>

                    <v-list-item-content>
                      <v-card-title> Endereço </v-card-title>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="form.estado"
                          autocomplete="autocomplete_off_hack_xfr4!k"
                          label="Estado *"
                          name="estado"
                          :items="estado"
                          :rules="requiredRules"
                          :loading="loadingEstados"
                          :disabled="loadingEstados"
                          item-text="Nome"
                          item-value="CodEstado"
                          required
                          @change="changeCidade"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="form.CodCidade"
                          name="cidade"
                          autocomplete="autocomplete_off_hack_xfr4!k"
                          label="Cidade *"
                          :loading="loadingCidades"
                          :items="cidade"
                          :rules="requiredRules"
                          item-text="Nome"
                          item-value="CodCidade"
                          required
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="form.logradouro"
                          label="Logradouro"
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-text-field v-model="form.numero" label="Numero" />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="form.bairro" label="Bairro" />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.complemento"
                          label="Complemento"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.cep"
                          v-mask="maskCep"
                          type="text"
                          label="CEP"
                          name="cep"
                          required
                        />
                      </v-col>
                    </v-list-item-content>
                  </v-list>
                </v-list-item-content>
              </v-card>

              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.id ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>

              <router-link
                :disabled="salvando"
                :to="`/${$user.get().role}/prestador`"
              >
                <v-btn :disabled="salvando" color="error" class="ml-3">
                  Cancelar
                </v-btn>
              </router-link>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import { validaCPF } from '../../utils/validacoes'
import { validarCNPJ } from '../../utils/validacoes'

export default {
  data() {
    return {
      providers: [],
      providersData: [],
      loadingProviders: false,
      providerCod: '',
      data: [],
      select: '',
      edit: false,
      agroindustrias: [],
      salvando: false,
      loading: true,
      loadingEstados: true,
      loadingCidades: false,
      loadingAgroindustrias: true,
      sexos: [{ text: 'Masculino' }, { text: 'Feminino' }],
      maskCpfCnpj: ['###.###.###-##', '##.###.###/####-##'],
      maskCpf: '###.###.###-##',
      maskCnpj: '##.###.###/####-##',
      maskTelCel: '(##) # ####-####',
      maskCep: '##.###-###',
      emailRules: [v => /.+@.+/.test(v) || 'E-mail inválido'],
      telRules: [
        v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
        v => !!v || '* Obrigatório',
      ],
      cnpjRules: [
        v => validarCNPJ(v) || '* CNPJ inválido',
        v => !!v || '* Obrigatório',
      ],
      cpfRules: [
        v => !!v || '* Obrigatório',
        v => validaCPF(v) || '* CPF inválido',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      valid: false,
      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',
      form: {
        id: null,
        nome: null,
        nomeFantasia: null,
        razaoSocial: null,
        email: null,
        cpf: null,
        cnpj: null,
        sexo: null,
        telCel: null,
        estado: null,
        CodCidade: null,
        bairro: null,
        logradouro: null,
        numero: null,
        complemento: null,
        cep: null,
        telRes: null,
        select: null,
      },
      estado: [],
      cidade: [],
      cadastro: this.$route.query.id === 0 ? 1 : 0,
    }
  },

  created() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.edit = true
      this.formDados()
    } else {
      this.loadNewForm()
    }
  },
  methods: {
    updateProvider() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        return false
      } else {
        if (this.form.id) {
          this.salvando = true
          this.api.update
            .prestador(this.form.id, this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/prestador`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        } else {
          this.salvando = true
          this.api.save
            .prestador(this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/prestador`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        }
      }
    },
    loadNewForm() {
      this.form.nome = null
      this.form.email = null
      this.form.cpf = null
      this.form.cnpj = null
      this.nomeFantasia = null
      this.razaoSocial = null
      this.form.telCel = null
      this.form.estado = null
      this.form.CodCidade = null
      this.form.bairro = null
      this.form.logradouro = null
      this.form.numero = null
      this.form.complemento = null
      this.form.cep = null
      this.form.telRes = null
      this.loadEstado()
      this.loading = false
      this.dialog = true
    },
    formDados() {
      this.api.get.prestador(this.form.id).then(data => {
        this.form.select = data?.tipoPessoaFisica
        this.select = data?.tipoPessoaFisica
        this.form.nome = data?.pessoa?.pessoafisica?.Nome
        this.form.nomeFantasia = data?.pessoa?.pessoajuridica?.NomeFantasia
        this.form.razaoSocial = data?.pessoa?.pessoajuridica?.RazaoSocial
        this.form.email = data?.pessoa?.email
        this.form.cpf = data?.pessoa?.pessoafisica?.Cpf
        this.form.cnpj = data?.pessoa?.pessoajuridica?.Cnpj
        this.form.telCel = data?.pessoa?.TelCel
        this.form.estado = data?.pessoa?.endereco?.cidade?.CodEstado
        this.form.CodCidade = data?.pessoa?.endereco?.cidade?.CodCidade
        this.form.bairro = data?.pessoa?.endereco?.Bairro
        this.form.logradouro = data?.pessoa?.endereco?.Logradouro
        this.form.numero = data?.pessoa?.endereco?.Numero
        this.form.complemento = data?.pessoa?.endereco?.Complemento
        this.form.cep = data?.pessoa?.endereco?.Cep
        this.form.telRes = data?.pessoa?.TelRes ?? ''
        this.loadEstado()
        this.loadCidade(this.form.estado)
        this.loading = false
        this.valid = true
      })
    },
    loadEstado() {
      this.api.get.estados().then(data => {
        this.estado = data
        this.loadingEstados = false
      })
    },
    loadCidade(CodEstado) {
      this.loadingCidades = true
      this.api.get.cidades(CodEstado).then(data => {
        this.cidade = data
        this.loadingCidades = false
      })
    },
    changeCidade() {
      this.loadCidade(this.form.estado)
    },
  },
}
</script>
